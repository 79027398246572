import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useIsSmallScreenFalse } from 'packages/core'
import { useApplication, useApplicationRoles } from 'hooks'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useHistory } from 'react-router'
import DeleteAzureApplicationRight from './DeleteAzureApplicationRight'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { RightTypes } from 'utils'

const ApplicationRights = ({
    applicationId,
    resourceId,
    isPbacApplication = false,
}) => {
    const { t } = useTranslation()
    const { data } = useApplication(applicationId)

    const history = useHistory()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const canAddAzureApplicationRole = hasAccessToControl(
        'ResourceAdmin-ApplicationDetails-AppRights-AddAzureApplicationRole-Control',
    )

    const canAddPbacApplicationRole = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-AppRights-AddPbacApplicationRole-Control`,
    )

    const canDeleteAureApplicationRole = hasAccessToControl(
        `ResourceAdmin-ApplicationDetails-AppRights-DeleteAzureApplicationRole-Control`,
    )

    const { list, loading, loadingMore, pagination } = useApplicationRoles(
        applicationId,
        RightTypes.AzureRole,
    )

    const rolesAttributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'DisplayName',
            isHeader: true,
            showInCard: false,
            //NOTE : Keeping the commented out code for subcomponent traceability
            // requireAccess: {
            //     control:
            //         'ResourceAdmin-ApplicationDetails-AppRights-DisplayName',
            // },
        },
        {
            name: 'name',
            sortable: true,
            label: 'TechnicalName',
            isHeader: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-TechnicalName',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-Description',
            },
        },
        {
            name: 'pbacResourceTypeFriendlyName',
            sortable: true,
            label: 'ResourceType',
            display: isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-ResourceType',
            },
        },
        {
            name: 'rightAllowedAssigeeTypeFriendlyName',
            sortable: true,
            label: 'AllowedMemberTypes',
            display: !isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-AllowedMemberTypes',
            },
        },
        {
            name: 'name',
            label: 'Value',
            sortable: true,
            display: !isPbacApplication,
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-AppRights-Value',
            },
        },
        {
            name: 'fulfillmentGroupFriendlyName',
            label: 'FulfillmentGroup',
            sortable: true,
            display: !isPbacApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppRights-FulfillmentGroup',
            },
        },
        {
            name: 'globalRightId',
            label: 'ID',
            sortable: true,
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-AppRights-ID',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-AppRights-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button.Details
                                item={item}
                                onClick={() => {
                                    history.push(
                                        `${history.location.search}&appRoleId=${item?.id}`,
                                    )
                                }}
                            />

                            <span style={{ marginRight: '10px' }} />
                            {canDeleteAureApplicationRole &&
                                item?.isDeletable && (
                                    <DeleteAzureApplicationRight item={item} />
                                )}
                            <span style={{ marginRight: '10px' }} />
                            <UIActionsGearButton
                                item={item}
                                noun="ResourceAdminRoles"
                            />
                        </Box>
                    )
                },
            },
        },
    ]

    const isSmallScreen = useIsSmallScreenFalse()

    const showAzureWorkflowButton =
        canAddAzureApplicationRole &&
        !isPbacApplication &&
        !data?.isBuiltInSystemApp
    const showPbacWorkflowButton =
        canAddPbacApplicationRole &&
        isPbacApplication &&
        !data?.isBuiltInSystemApp

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {(showAzureWorkflowButton || showPbacWorkflowButton) && (
                    <Box
                        data-protectedsubcomponent={
                            showAzureWorkflowButton
                                ? canAddAzureApplicationRole
                                : canAddPbacApplicationRole
                        }
                    >
                        <CreateWorkflowButton
                            label={t('AddApplicationAppRights')}
                            workflowName={
                                showAzureWorkflowButton
                                    ? 'CreateAzureAppAppRole'
                                    : 'OnboardAzLocalRight'
                            }
                            resourceId={resourceId}
                            selectedApplicationId={applicationId}
                        />
                    </Box>
                )}

                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    attributes={getAccessBasedAttributes(
                        rolesAttributes,
                    ).filter((a) => !a.hasOwnProperty('display') || a.display)}
                    rowSelection={false}
                    pagination={pagination}
                    list={list}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default ApplicationRights
