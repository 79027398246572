import { Box, Typography, styled, Dialog, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button, PolicyDetails } from 'components'
import {
    Loader,
    Pagination,
    Select,
    ToggleSwitch,
    Tooltip,
} from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Search from 'components/Filters/Search'
import {
    useAccessRequestPolicies,
    useAddItemsToCart,
    useApplication,
    useCreateCart,
    useIAmShopAssignees,
    useModalSearchForAssignmentPopup,
} from 'hooks'
import { isNilOrEmpty, useDebounce, useQuery } from 'packages/core'
import classNames from 'classnames'
import { PageContextProvider, usePageContext } from 'pageContext'
import { paginate, RESOURCE_TYPE_NAMES } from 'utils'
import { EndDateTimePicker } from 'components/TimeConstrainedControl/EndDateTimePicker'
import { StartDateTimePicker } from 'components/TimeConstrainedControl/StartDateTimePicker'
import { useAppState } from 'appContext'
import { PillTabs } from 'components/PillTabs'
import { Tab } from 'components/PillTabs/Tab'
import { TabLabel } from 'components/PillTabs/TabLabel'
import AssignmentItemWithFieldTypes from './AssignmentItemWithFieldTypes'
import cartHelpers from 'containers/Cart/cartHelpers'
import useSubcomponents from 'useSubcomponents'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        right: '0rem',
        cursor: 'pointer',
        marginTop: '-1.2rem',
    },
})

const AssignmentsModal = styled(Box)({
    maxWidth: '44rem',
    width: '100%',
    background: '#fff !important',
    border: 'solid 1px #ebebed',
    '& h2': {
        fontSize: '2.4rem',
        color: '#282828',
    },
})

export const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '1px',
    margin: '2.4rem 0',
})
export const InputBox = styled(Box)({
    '& input': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '3rem',
        width: '100%',
        borderRadius: '5px',
        outline: 'none',
        padding: '5px 15px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '3rem',
        borderRadius: '5px',
        outline: 'none',
        padding: '0.5rem 1.5rem',
        background: '#fff !important',
    },

    '& .MuiInputBase-fullWidth': {
        maxWidth: '36rem',
    },
    '& MuiSelect-selectMenu': {
        fontSize: '1.4rem',
    },

    '& .MuiInput-underline': {
        '&:after': {
            border: 'none !important',
        },
        '&:before': {
            border: 'none !important',
        },
    },
})
export const ModalFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    '& button': {
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        padding: '0.4rem 1.6rem 0.4rem 1.3rem', // 4px 16px 4px 13px
        width: '100%',
        borderRadius: '0.4rem', // 4px
        outline: 'none',
        height: '3.2rem', // 32px
        fontSize: '1.4rem', // 14px
        '& svg': {
            marginLeft: '0.8rem', // 8px
        },
        '&:nth-child(1)': {
            background: theme?.palette?.primary?.main,
            color: '#fff',
            maxWidth: '24rem', // 240px
        },
        '&:nth-child(2)': {
            color: theme?.palette?.primary?.main,
            marginLeft: '1.6rem', // 16px
            maxWidth: '9rem', // 90px
        },
    },
}))

export const CustomDialog = styled(Dialog)({
    zIndex: 1200,
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '83rem', // 830px
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '83rem', // 830px
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const useStyles = makeStyles((theme) => ({
    headerBox: {
        padding: '3.2rem 2rem 3.2rem 4rem',
        backgroundColor: '#ffffff !important',
        maxHeight: '8.4rem',
    },
    bodyBox: {
        padding: '2.4rem 3.2rem 3.2rem 3.2rem',
    },
    toggleSwitch: {
        '& .MuiFormControlLabel-label': {
            wordBreak: 'break-word',
        },
    },

    ButtonRemoveSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '4rem',
        '& button': {
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content !important',
            gap: '10px',
            borderRadius: '5px',
            '& label': {
                maxWidth: '85%',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                TextOverflow: 'ellipsis',
                wordWrap: 'break-word',
                cursor: 'pointer',
            },
        },
    },
    SearchPeople: {
        marginBottom: '2.4rem',
        '& p': {
            fontSize: '1.6rem',
            marginBottom: '1.6rem',
        },
        '& svg': {
            marginLeft: '0 !important',
        },
    },

    timeConstraintSwitchButton: {
        backgroundColor: '#fbfbfc',
        color: '#4a4a4a !important',
        border: 'solid 1px #b2b5bb',
        borderRadius: '5px',
        padding: '1.6rem',
        minHeight: '5.6rem', // 56px
        '&:hover': {
            backgroundColor: '#fbfbfc',
            border: 'solid 1px #265fb4',
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    activeTimeConstraintSwitchButton: {
        color: '#000000 !important',
        border: 'solid 1px #265fb4',
        '&:hover': {
            color: '#000000 !important',
        },
    },
    disabledTimeConstraintSwitchButton: {
        color: 'rgba(0, 0, 0, 0.26) !important',
    },
    searchBar: {
        width: '26rem !important', // 260px
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        '& div': {
            borderRadius: '4px',
        },
    },
    inactiveButtonForAdd: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        marginTop: '6rem', // 60px
        marginBottom: '2rem', // 20px
        '& svg': {
            width: '1.2rem', // 12px
            position: 'absolute',
            right: '0.5rem', // 5px
        },
    },
    activeButtonForAdd: {
        backgroundColor: '#01ae8f',
        color: '#fff !important',
        borderColor: '#01ae8f !important ',
        '&:hover': {
            backgroundColor: '#01ae8fcc !important',
        },
        '& svg': {
            color: '#fff !important',
            fill: '#fff !important',
        },
    },

    inactiveButtonForRemove: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        marginBottom: '2rem', // 20px
        '& svg': {
            width: '1.2rem', // 12px
            position: 'absolute',
            left: '0.5rem', // 5px
        },
    },
    activeButtonForRemove: {
        color: `${theme?.palette.error.main} !important`,
        borderColor: `${theme?.palette.error.main} !important `,

        '& svg': {
            color: `${theme?.palette.error.main} !important`,
            fill: `${theme?.palette.error.main} !important`,
        },
    },

    inactiveButtonForRemoveAll: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        '& svg': {
            width: '1.2rem', // 12px
            position: 'absolute',
            left: '0.5rem', // 5px
        },
    },
    activeButtonForRemoveAll: {
        color: `${theme?.palette.error.main} !important`,
        borderColor: `${theme?.palette.error.main} !important `,

        '& svg': {
            color: `${theme?.palette.error.main} !important`,
            fill: `${theme?.palette.error.main} !important`,
        },
    },
    TimeSectionFooter: {
        marginTop: '3.2rem',
        '& button': {
            maxWidth: '100% !important',
            width: 'fit-content',
        },
    },
    SelectAssignmentSectionContainer: {
        background: '#fff !important',
        border: 'solid 1px #ebebed  !important',
        borderRadius: '0.5rem !important', // 5px
        width: '26rem !important', // 260px
        borderTop: 'none !important',
    },

    AssignmentsModalloader: {
        background: '#fff !important',
        height: '100%',
        '& .MuiBox-root': {
            background: '#fff !important',
        },
    },
    AssignmentsAllSelectedContainer: {
        display: 'flex',
        justifyContent: 'center',
        background: '#fff !important',
        height: '100%',
    },

    AssignmentsModalAllSelected: {
        padding: '1rem', // 10px
        background: '#fff !important',

        '& svg': {
            position: 'relative',
            top: '0.4rem', // 4px
            marginRight: '0.6rem', // 6px
        },
    },
    tabsContainer: {
        display: 'flex',
        width: '101% !important',
        marginBottom: '-0.3rem', // -3px
        marginLeft: '-0.1rem', // -1px
        zIndex: 999,
    },
}))

const SelectAssignmentSection = styled(Box)(({ theme }) => ({
    background: `${theme?.palette.common.white} !important`,
    width: '26rem !important', // 260px
    overflow: 'hidden',
    height: '37.6rem',
    overflowY: 'auto',
    padding: '0.5rem', // 5px
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}))
const PaginationMain = styled(Box)({
    width: '26rem !important', // 260px
    marginTop: '1.5rem', // 15px
    '& div:first-child': {
        justifyContent: 'center',
    },
    '& .MuiBox-root': {
        padding: '0 !important',
    },

    '& ul': {
        height: '3rem', // 30px
        borderRadius: '2rem', // 20px
        flexWrap: 'nowrap !important',
        '& li': {
            '& button': {
                padding: '0 0.2rem', // 0px 2px
            },
        },
    },
})
const ButtonSection = styled(Box)({
    marginLeft: '1.5rem', // 15px
    marginRight: '1.5rem', // 15px
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '25rem !important', // 190px
    gap: '10rem', // 100px
    '& button': {
        minWidth: '12.5rem', // 125px
        cursor: 'pointer',
        fontSize: '1.4rem', // 14px
        borderRadius: '0.4rem', // 4px
        height: '3.1rem', // 31px
    },
})
//Below page size is for Client Side pagination
const pageSize = 7

export const PermissionLevels = ({
    value,
    permissionLevelProps,
    onChange,
    applicationId,
}) => {
    const { t } = useTranslation()

    const { list: accessLevels } = useIAmShopAssignees(applicationId, {
        take: 10000,
    })
    const { permissionLabelProp } = permissionLevelProps
    const getOptionLabel = (option) => option[permissionLabelProp]

    return (
        <InputBox>
            <Typography variant="subtitle1">
                {t('IAmShopPermissionLevel')}
            </Typography>
            <Select
                value={value}
                options={accessLevels?.length > 0 ? accessLevels : []}
                placeholder={t('GetPermissionLevel')}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                required
            />
        </InputBox>
    )
}

const EditAssignmentsWithFieldTypes = ({
    unAssignedUrl, //Left
    assignedUrl, //Right
    identifier = 'id',
    showPermissionLevel,
    filterWithAccessLevel = false,
    selectedApplicationId,
    targetAssigneeId,
    unAssignedQueryParams = {},
    assignedQueryParams = {},
    listLabels = {
        unselectedListLabel: 'All',
        selectedListLabel: 'Assigned',
    },
    searchPlaceHolder = 'SearchFilterMembers',

    hideDurationSelection = false,
    permissionLevelProps = {
        permissionLabelProp: 'value',
        permissionIdetifierProp: 'id',
    },
    handleClose = null,
    showAssignmentModal = false,
    // NOTE: Need to refine with Shahid. Adding for now in order not to break old logic
    secondaryAttributes = ['email'],
    primaryAttribute = 'friendlyName',
    showAccessRequestPolicy = false,
    requestPolicyId,
    requestPolicyIdProperty = 'accessRequestPolicyId',
    fetchRequestPolicyItemWise = false,
    showTimeConstrainedFilter = false,
    showStartDate = true,
    // For Edit Person applications
    addSelectedOnly = false,
    assignedPeopleList = null,
    unAssignedPeopleList = null,
    fieldTypeProps = {},
    newMappingForAssignedList = {},
    requireRemappingInAssignedList = false,
    onSubmit,
    closeOnSubmit = false,
    itemType,
    unAssignedFilterFn = null,
    unAssignedPredefinedList = [],
    shouldReverseCarts = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const theme = useTheme()
    const [, dispatch] = usePageContext()
    const [{ devFeaturesEnabled }, appDispatch] = useAppState()

    const [openAssigneeModal, setOpenAssigneeModal] =
        useState(showAssignmentModal)
    const [poilicyAssignee, setPolicyAssignee] = useState(null)
    const [searchKey, setSearchKey] = useState('')
    const [selectedTabLeft, setSelectedTabLeft] = useState('all')
    const [selectedTabRight, setSelectedTabRight] = useState('assigned')
    const debouncedSearchValue = useDebounce(searchKey)
    const [selectedAssignmentsRight, setSelectedAssignmentsRight] = useState([])
    const [selectedAssignmentsLeft, setSelectedAssignmentsLeft] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const query = useQuery()

    const applicationId = query.get('applicationId') || selectedApplicationId
    const { data: application } = useApplication(applicationId)
    const [addItemsToCart] = useAddItemsToCart()

    const [createCart] = useCreateCart()

    const { hasAccessToControl } = useSubcomponents()
    const canViewAssignmentPopupDurationControl =
        'ResourceAdmin-CanViewAssignmentPopupDuration-Control'

    const SECTION_TABS_LEFT = [
        {
            label: t(listLabels.unselectedListLabel),
            value: 'all',
            countProp: 'unAssignedCount',
            countColor: theme?.palette.primary.main,
        },
        {
            label: t('Removed'),
            value: 'removed',
            countProp: 'removedCount',
            countColor: '#d0021b',
        },
    ]
    const SECTION_TABS_RIGHT = [
        {
            label: t(listLabels.selectedListLabel),
            value: 'assigned',
            countProp: 'assignedCount',
            countColor: theme?.palette.primary.main,
        },
        {
            label: t('Added'),
            value: 'added',
            countProp: 'addedCount',
            countColor: theme?.palette.success.main,
        },
    ]

    const [page_LeftSide, setPage_LeftSide] = useState(1)
    const [page_RightSide, setPage_RightSide] = useState(1)

    const unAssignedQueryData = {
        searchTerm: selectedTabLeft === 'all' ? debouncedSearchValue : '',
        take: pageSize,
        skip: (page_LeftSide - 1) * pageSize,

        accessLevelId:
            showPermissionLevel && filterWithAccessLevel
                ? poilicyAssignee?.id
                : undefined,
        ...unAssignedQueryParams,
    }
    const assignedQueryData = {
        take: pageSize,
        skip: (page_RightSide - 1) * pageSize,
        ...assignedQueryParams,
    }
    const {
        list: unAssignedList,
        isInitialLoading: unAssignedLoading,
        totalCount: unAssignedTotalCount,
    } = useModalSearchForAssignmentPopup(
        unAssignedUrl,
        unAssignedQueryData,
        (selectedTabLeft === 'all' && filterWithAccessLevel
            ? Boolean(poilicyAssignee?.id)
            : openAssigneeModal) && unAssignedPredefinedList.length === 0,
    )
    const [assignedList, setAssignedList] = useState([])
    const [modifiedItems, setModifiedItems] = useState([])
    const {
        list: assignedItems,
        loading: assignedLoading,
        totalCount: assignedTotalCount,
    } = useModalSearchForAssignmentPopup(
        assignedUrl,
        assignedQueryData,
        filterWithAccessLevel
            ? Boolean(poilicyAssignee?.id)
            : openAssigneeModal,
    )
    const [newlyAddedItems, setNewlyAddedItems] = useState([])
    const [newlyRemovedItems, setNewlyRemovedItems] = useState([])
    const [saveLoader, setSaveLoader] = useState(false)
    useEffect(() => {
        if (requireRemappingInAssignedList && assignedItems?.length) {
            const reMapped = assignedItems.map((item) => {
                const newItem = {}
                for (const [newKey, oldKey] of Object.entries(
                    newMappingForAssignedList,
                )) {
                    newItem[newKey] = item[oldKey]
                }
                return { ...item, ...newItem }
            })
            setAssignedList(reMapped)
        } else {
            setAssignedList(assignedItems)
        }
    }, [assignedItems])

    useEffect(() => {
        if (selectedTabLeft === 'removed') {
            handleLocalSearchLeftSide()
        }
    }, [debouncedSearchValue])
    const handleLocalSearchLeftSide = () => {
        const searchResults = newlyRemovedItems
            .filter((x) => (itemType ? x.itemType === itemType : true))
            .filter((item) => {
                // Check primary attribute
                if (
                    item[primaryAttribute] &&
                    item[primaryAttribute]
                        .toLowerCase()
                        .includes(debouncedSearchValue.toLowerCase())
                ) {
                    return true
                }

                // Check secondary attributes
                return secondaryAttributes.some(
                    (secondaryAttr) =>
                        item[secondaryAttr] &&
                        item[secondaryAttr]
                            .toLowerCase()
                            .includes(debouncedSearchValue.toLowerCase()),
                )
            })
        return searchResults
    }
    // For Edit Person: To Show the already selected items on re open of edit modal
    useEffect(() => {
        if (!unAssignedLoading && !assignedLoading) {
            if (addSelectedOnly) {
                if (assignedPeopleList?.length >= 0) {
                    setNewlyAddedItems([
                        ...newlyAddedItems,
                        ...assignedPeopleList,
                    ])
                }
                if (unAssignedPeopleList?.length >= 0) {
                    setNewlyRemovedItems([
                        ...newlyRemovedItems,
                        ...unAssignedPeopleList,
                    ])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSelectedOnly, assignedPeopleList, unAssignedPeopleList])
    const filterUnAssignedListFromProps = () => {
        if (unAssignedFilterFn) {
            return unAssignedFilterFn(
                unAssignedList ? unAssignedList : unAssignedPredefinedList,
            )
        } else {
            return unAssignedList ? unAssignedList : unAssignedPredefinedList
        }
    }
    const tabCounts = {
        unAssignedCount:
            unAssignedPredefinedList.length > 0
                ? unAssignedPredefinedList.length
                : unAssignedTotalCount,
        removedCount: handleLocalSearchLeftSide()?.length,
        assignedCount: assignedTotalCount,
        addedCount: newlyAddedItems.filter((x) =>
            itemType ? x.itemType === itemType : true,
        ).length,
    }

    // Left Side Pagination
    const paginatedData_LeftSide =
        selectedTabLeft === 'all'
            ? filterUnAssignedListFromProps()
            : paginate(handleLocalSearchLeftSide(), page_LeftSide, pageSize)

    const numberOfPages_LeftSide =
        selectedTabLeft === 'all'
            ? filterUnAssignedListFromProps()
                ? Math.ceil(
                      unAssignedFilterFn
                          ? filterUnAssignedListFromProps()?.length
                          : unAssignedTotalCount / pageSize,
                  )
                : 0
            : Math.ceil(handleLocalSearchLeftSide().length / pageSize)

    const handlePageChange_LeftSide = (_, value) => {
        setPage_LeftSide(value)
    }

    //Right Side Pagination

    const paginatedData_RightSide =
        selectedTabRight === 'assigned'
            ? assignedList && assignedList
            : paginate(
                  newlyAddedItems.filter((x) =>
                      itemType ? x.itemType === itemType : true,
                  ),
                  page_RightSide,
                  pageSize,
              )

    const numberOfPages_RightSide =
        selectedTabRight === 'assigned'
            ? assignedList
                ? Math.ceil(assignedTotalCount / pageSize)
                : 0
            : Math.ceil(
                  newlyAddedItems.filter((x) =>
                      itemType ? x.itemType === itemType : true,
                  ).length / pageSize,
              )

    const handlePageChange_RightSide = (_, value) => {
        setPage_RightSide(value)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const lastSelectedItem =
        selectedAssignmentsLeft[selectedAssignmentsLeft?.length - 1]

    //NOTE : Time Constrained and Date properties are only for the last selected item
    const selectedStartDate = lastSelectedItem?.startDateUtc
    const selectedEndDate = lastSelectedItem?.endDateUtc
    const timeConstrained = lastSelectedItem?.timeConstrained

    const policyId = requestPolicyId
        ? requestPolicyId
        : fetchRequestPolicyItemWise
        ? lastSelectedItem?.[requestPolicyIdProperty] //Show the request access policy of last selected item
        : ''

    const { data: allAccessRequestPolicies } = useAccessRequestPolicies()

    const policy =
        showAccessRequestPolicy && policyId
            ? allAccessRequestPolicies[policyId]
            : null

    const violatesPolicy =
        policy?.isTimeConstrained &&
        policy?.maximumValueInMinutes &&
        moment(selectedEndDate).diff(moment(selectedStartDate), 'minutes') >
            policy?.maximumValueInMinutes

    const hasInvalidDates = timeConstrained
        ? isNilOrEmpty(selectedStartDate) ||
          isNilOrEmpty(selectedEndDate) ||
          violatesPolicy ||
          moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))
        : false

    let invalidDatesMessage = ''

    if (isNilOrEmpty(selectedStartDate)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (isNilOrEmpty(selectedEndDate)) {
        invalidDatesMessage = t('InvalidEndDateTime')
    }
    if (moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))) {
        invalidDatesMessage = t('EndDateGreaterThanStartDate')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('PolicyAllowsMaxDurationInMinError', {
            count: policy?.maximumValueInMinutes,
        })
    }

    const handleTimeConstrainedSwitch = () => {
        const selectedItemsLeft = [...selectedAssignmentsLeft]

        if (lastSelectedItem?.timeConstrained) {
            const updatedLastSelectedItem = {
                ...lastSelectedItem,
                timeConstrained: false,
                startDateUtc: null,
                endDateUtc: null,
            }
            selectedItemsLeft[selectedItemsLeft.length - 1] =
                updatedLastSelectedItem
            setSelectedAssignmentsLeft(selectedItemsLeft)
        } else {
            const updatedLastSelectedItem = {
                ...lastSelectedItem,
                timeConstrained: true,
                startDateUtc: policy?.defaultValueInMinutes
                    ? moment.utc().second(0).format()
                    : null,
                endDateUtc: policy?.defaultValueInMinutes
                    ? moment
                          .utc()
                          .second(0)
                          .add(policy.defaultValueInMinutes, 'minutes')
                          .format()
                    : null,
            }
            selectedItemsLeft[selectedItemsLeft.length - 1] =
                updatedLastSelectedItem
            setSelectedAssignmentsLeft(selectedItemsLeft)
        }
    }

    const handleStartDate = (startDateToSet) => {
        const selectedItemsLeft = [...selectedAssignmentsLeft]
        const updatedLastSelectedItem = {
            ...lastSelectedItem,
            startDateUtc: startDateToSet,
        }
        selectedItemsLeft[selectedItemsLeft.length - 1] =
            updatedLastSelectedItem
        setSelectedAssignmentsLeft(selectedItemsLeft)
    }
    const handleEndDate = (endDateToSet) => {
        const selectedItemsLeft = [...selectedAssignmentsLeft]
        const updatedLastSelectedItem = {
            ...lastSelectedItem,
            endDateUtc: endDateToSet,
        }

        selectedItemsLeft[selectedItemsLeft.length - 1] =
            updatedLastSelectedItem
        setSelectedAssignmentsLeft(selectedItemsLeft)
    }

    const handlePolicyAssigneChange = (evt) => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        setPolicyAssignee(evt)
    }

    const handleModalClose = () => {
        setOpenAssigneeModal(false)
        setSearchKey('')
        setSelectedAssignmentsLeft([])
        setNewlyAddedItems([])
        setNewlyRemovedItems([])
        setSelectedAssignmentsRight([])
        setPolicyAssignee(null)
        setPage_LeftSide(1)
        setPage_RightSide(1)
        setSelectedTabLeft('all')
        setSelectedTabRight('assigned')
        setAssignedList([])
        setIsSubmitting(false)
        setModifiedItems([])

        if (handleClose) {
            handleClose()
        }
    }

    const checkIfSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (checkIfAlreadyAddedToSelectedList(item)) {
            selectedAttribute.alreadyAdded = true
        }
        if (isSelectedToAdd(item, selectedAssignmentsLeft)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const getValueFromSelectedArray = (item, property) => {
        const selectedItem = selectedAssignmentsLeft.find(
            (x) => x[identifier] === item[identifier],
        )
        if (selectedItem) {
            return selectedItem[property]
        }
        return null
    }

    const handleSelectPeople = (_item) => {
        const item = { ..._item }
        item.itemType = itemType ? itemType : null
        // Check if already added
        if (checkIfAlreadyAddedToSelectedList(item)) {
            return
        }

        const itemAccessRequestPolicy =
            allAccessRequestPolicies[item[requestPolicyIdProperty]]

        if (selectedTabLeft === 'all') {
            // Set the default time duration of the item at the time of selection only in case of adding
            if (itemAccessRequestPolicy?.isTimeConstrained) {
                item.timeConstrained = true
                if (itemAccessRequestPolicy?.defaultValueInMinutes) {
                    item.startDateUtc = moment.utc().second(0).format()
                    item.endDateUtc = moment
                        .utc()
                        .second(0)
                        .add(
                            itemAccessRequestPolicy.defaultValueInMinutes,
                            'minutes',
                        )
                        .format()
                }
            }
        }

        // If first selection add to list only
        if (!selectedAssignmentsLeft?.length) {
            setSelectedAssignmentsLeft([item])
            return
        }

        //  Check if item already added then update list
        setSelectedAssignmentsLeft(
            addRemoveOnSelectionFromList(item, selectedAssignmentsLeft),
        )
    }

    const isSelectedToAdd = (item, list) => {
        return list.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const checkIfAlreadyAddedToSelectedList = (item) => {
        return newlyAddedItems.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const handleAddSelectedPeople = () => {
        if (selectedAssignmentsLeft.length > 0) {
            if (selectedTabLeft === 'all') {
                const newlyItemstoAdd = selectedAssignmentsLeft.filter(
                    (i) => i.itemTab === 'all',
                )

                setSelectedTabRight('added')
                setNewlyAddedItems([...newlyAddedItems, ...newlyItemstoAdd])
            } else {
                const arrayAfterDeletingCommon = deleteCommonElements(
                    newlyRemovedItems,
                    selectedAssignmentsLeft,
                )
                setSelectedTabRight('assigned')

                setNewlyRemovedItems(arrayAfterDeletingCommon)
            }
            setSelectedAssignmentsLeft([])
        }
    }

    const checkIfRemoveSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyRemoved: false,
        }
        if (isSelectedToAdd(item, selectedAssignmentsRight)) {
            selectedAttribute.isSelected = true
        }
        if (isAssignedItemRemoved(item)) {
            selectedAttribute.alreadyRemoved = true
        }
        return selectedAttribute
    }
    const isAssignedItemRemoved = (item) => {
        return newlyRemovedItems.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }
    const handleAddedPeopleChange = (item) => {
        if (
            item?.isInherited &&
            item?.isAssigned &&
            selectedTabRight === 'assigned'
        ) {
            return
        }
        setSelectedAssignmentsRight(
            addRemoveOnSelectionFromList(item, selectedAssignmentsRight),
        )
    }

    const addRemoveOnSelectionFromList = (item, list) => {
        let selectedValues = [...list]
        const memberIndex = selectedValues.findIndex(
            (x) => x[identifier] === item[identifier],
        )
        if (memberIndex < 0) {
            selectedValues.push(item)
        } else {
            selectedValues.splice(memberIndex, 1)
        }

        return selectedValues
    }

    const handleRemove = (actionType) => {
        if (actionType === 'all') {
            if (selectedTabRight === 'assigned') {
                const newItemsToRemove = getElementsNotPresent(
                    paginatedData_RightSide.filter(
                        (i) =>
                            !(i.isInherited && selectedTabRight === 'assigned'),
                    ),
                    newlyRemovedItems,
                )

                if (itemType) {
                    newItemsToRemove.forEach((x) => {
                        x.itemType = itemType ? itemType : null
                    })
                }

                setNewlyRemovedItems([
                    ...newlyRemovedItems,
                    ...newItemsToRemove,
                ])
                setSearchKey('')
                setSelectedTabLeft('removed')
            } else {
                const newlyAddedItemsCopy = [...newlyAddedItems]
                const startIndex = (page_RightSide - 1) * pageSize

                if (itemType) {
                    //Note : Remove only those items which have the same itemType
                    const filteredItems = newlyAddedItemsCopy.filter(
                        (item) => item.itemType === itemType,
                    )

                    filteredItems.splice(startIndex, pageSize)

                    const resultArray = newlyAddedItemsCopy
                        .filter((item) => item.itemType !== itemType)
                        .concat(filteredItems)

                    setNewlyAddedItems(resultArray)
                } else {
                    newlyAddedItemsCopy.splice(startIndex, pageSize)
                    setNewlyAddedItems(newlyAddedItemsCopy)
                }
                if (page_RightSide !== 1) {
                    setPage_RightSide((pre) => pre - 1)
                }
                setSearchKey('')
                setSelectedTabLeft('all')
            }
            setSelectedAssignmentsRight([])
        } else {
            if (selectedTabRight === 'assigned') {
                const elementsToRemove = getElementsNotPresent(
                    selectedAssignmentsRight,
                    newlyRemovedItems,
                )
                setSearchKey('')

                if (itemType) {
                    elementsToRemove.forEach((x) => {
                        x.itemType = itemType ? itemType : null
                    })
                }

                setSelectedTabLeft('removed')
                setNewlyRemovedItems((pre) => [...pre, ...elementsToRemove])
            } else {
                const arrayAfterDeletingCommon = deleteCommonElements(
                    newlyAddedItems,
                    selectedAssignmentsRight,
                )
                setSearchKey('')

                setSelectedTabLeft('all')
                setNewlyAddedItems(arrayAfterDeletingCommon)
            }
        }
        setSelectedAssignmentsRight([])
    }
    const getElementsNotPresent = (array1, array2) => {
        // Filter elements from array1 that are not present in array2 based on the identifier
        return array1.filter(
            (element1) =>
                !array2.some(
                    (element2) => element1[identifier] === element2[identifier],
                ),
        )
    }

    const handleSave = () => {
        setIsSubmitting(true)
        if (onSubmit) {
            onSubmit(newlyAddedItems, newlyRemovedItems)
            if (closeOnSubmit) {
                handleModalClose()
            }
        } else {
            try {
                let itemsToBeAdded = newlyAddedItems.reduce(
                    (cartsArray, item) => {
                        if (
                            item.fieldTypeObject &&
                            item.fieldTypeObject.itemToAdd &&
                            item.fieldTypeObject.itemToAdd.cartObj
                        ) {
                            // Map through each item in the cartObj array and conditionally add endDateUtc
                            const updatedCartObjects =
                                item.fieldTypeObject.itemToAdd.cartObj.map(
                                    (cartItem) => ({
                                        ...cartItem,
                                        ...(item.endDateUtc
                                            ? {
                                                  endDateUtc: item.endDateUtc,
                                                  timeConstraintActive: true,
                                              }
                                            : {}), // Add endDateUtc only if it's not null
                                    }),
                                )
                            return cartsArray.concat(updatedCartObjects)
                        } else {
                            return cartsArray.concat({
                                ...preAdd(item),
                                targetAssigneeFriendlyName: item.friendlyName,
                                targetAssigneeId: item?.[identifier],
                                // resourceType_Name: fieldTypeProps?.isRole
                                //     ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                                //     : t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                                // NOTE: Found that above targetAssigneeFriendlyName was being wrongly overrideen so commented it out
                                ...(item.endDateUtc
                                    ? {
                                          endDateUtc: item.endDateUtc,
                                          timeConstraintActive: true,
                                      }
                                    : {}),
                            })
                        }
                    },
                    [],
                )
                const itemsToBeRemoved = newlyRemovedItems?.map((r) => {
                    return {
                        ...preRemove(r),
                        targetAssigneeFriendlyName: r.friendlyName,
                        targetAssigneeId:
                            r?.[fieldTypeProps?.keyForRoleRightId],
                        // resourceType_Name: fieldTypeProps?.isRole
                        //     ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                        //     : t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                        // NOTE: Found that above targetAssigneeFriendlyName was being wrongly overrideen so commented it out

                        assignmentId: r?.[identifier],
                    }
                })

                const filteredArray = modifiedItems.filter(
                    (item1) =>
                        !itemsToBeRemoved.some(
                            (item2) => item2.assignmentId === item1[identifier],
                        ),
                )

                const itemsToBeModified = filteredArray.reduce(
                    (cartsArray, item) => {
                        if (
                            item.fieldTypeObject &&
                            item.fieldTypeObject.itemToAdd &&
                            item.fieldTypeObject.itemToAdd.cartObj
                        ) {
                            return cartsArray.concat(
                                item.fieldTypeObject.itemToAdd.cartObj,
                            )
                        }
                        return cartsArray
                    },
                    [],
                )

                var combinedItems = [
                    ...itemsToBeAdded,
                    ...itemsToBeRemoved,
                    ...itemsToBeModified,
                ]
                if (shouldReverseCarts) {
                    combinedItems = combinedItems.map((i) => {
                        return {
                            ...i,
                            requestableResourceId: i.targetAssigneeId,
                            resource_FriendlyName: i.targetAssigneeFriendlyName,
                            resourceType_Name: i?.itemType
                                ? i.itemType === 'role'
                                    ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                                    : t(RESOURCE_TYPE_NAMES.LOCALRIGHT)
                                : fieldTypeProps?.isRole
                                ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                                : t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
                            targetAssignee_ResourceTypeFriendlyName: t(
                                RESOURCE_TYPE_NAMES.Person,
                            ),
                            targetAssigneeFriendlyName: i.resource_FriendlyName,
                            targetAssigneeId: i.requestableResourceId,
                        }
                    })
                }
                createMultipleCarts(combinedItems).then(() => {
                    const groupedByTargetAssigneeId = combinedItems.reduce(
                        (acc, item) => {
                            // Use the targetAssigneeId as the key for the grouped object
                            const key = item.targetAssigneeId
                            if (!acc[key]) {
                                acc[key] = []
                            }
                            acc[key].push(item)
                            return acc
                        },
                        {},
                    )

                    Object.keys(groupedByTargetAssigneeId).forEach((key) => {
                        addItemsToCart(groupedByTargetAssigneeId[key]).then(
                            () => {
                                handleModalClose()
                            },
                        )
                    })
                })
            } catch (err) {}
        }
    }
    const createMultipleCarts = (array) => {
        const payloads = array.map((el) => {
            return {
                targetAssigneeId: el?.targetAssigneeId,
                targetAssigneeFriendlyName: el?.targetAssigneeFriendlyName,
                targetAssignee_ResourceTypeFriendlyName:
                    el?.targetAssignee_ResourceTypeFriendlyName
                        ? el?.targetAssignee_ResourceTypeFriendlyName
                        : el?.itemType
                        ? el.itemType === 'role'
                            ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                            : t(RESOURCE_TYPE_NAMES.LOCALRIGHT)
                        : fieldTypeProps?.isRole
                        ? t(RESOURCE_TYPE_NAMES.LOCALROLE)
                        : t(RESOURCE_TYPE_NAMES.LOCALRIGHT),
            }
        })
        const uniquePayloads = payloads.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.targetAssigneeId === value.targetAssigneeId,
                ),
        )
        const promises = []
        appDispatch({
            type: 'SET_TARGET_RESOURCE',
            payload: uniquePayloads[0],
        })
        uniquePayloads.forEach((i) => {
            promises.push(createCart(i))
        })
        return Promise.all(promises)
    }
    const getTooltipTitle = () => {
        if (showPermissionLevel && !poilicyAssignee) {
            return t('PermissionLevelRequired')
        }

        // For Edit Person: If user want to change previous selection on re opening of edit
        if (
            addSelectedOnly &&
            (assignedPeopleList?.length || unAssignedPeopleList?.length)
        ) {
            return ''
        }

        if (
            newlyAddedItems.length <= 0 &&
            newlyRemovedItems.length <= 0 &&
            modifiedItems.length <= 0
        ) {
            return t('NoChangeInAssignment')
        } else {
            if (newlyAddedItems.length > 0) {
                const isRequiredFieldMissing = newlyAddedItems?.filter(
                    (i) =>
                        i.isRequiredFieldType &&
                        !i.fieldTypeObject?.itemToAdd?.cartObj?.length,
                )
                if (isRequiredFieldMissing.length) {
                    return t('CompleteMandatoryFields')
                }
                return ''
            }
        }

        return ''
    }
    const hasTooltipValue = getTooltipTitle() !== ''

    const handleDoubleClickForAdding = (e, _item) => {
        const item = { ..._item }
        if (selectedTabLeft === 'removed') {
            const arrayAfterDeletingCommon = deleteCommonElements(
                newlyRemovedItems,
                [item],
            )
            setSelectedTabRight('assigned')
            setNewlyRemovedItems(arrayAfterDeletingCommon)
        } else {
            if (hasInvalidDates) {
                return
            }
            if (checkIfAlreadyAddedToSelectedList(item)) {
                return
            }
            const itemAccessRequestPolicy =
                allAccessRequestPolicies[item[requestPolicyIdProperty]]

            if (selectedTabLeft === 'all') {
                // Set the default time duration of the item at the time of selection only in case of adding
                if (itemAccessRequestPolicy?.isTimeConstrained) {
                    item.timeConstrained = true
                    if (
                        itemAccessRequestPolicy?.defaultValueInMinutes &&
                        !item.startDateUtc &&
                        !item.endDateUtc
                    ) {
                        item.startDateUtc = moment.utc().second(0).format()
                        item.endDateUtc = moment
                            .utc()
                            .second(0)
                            .add(
                                itemAccessRequestPolicy.defaultValueInMinutes,
                                'minutes',
                            )
                            .format()
                    }
                }
            }

            item.itemType = itemType ? itemType : null
            setSelectedTabRight('added')
            setNewlyAddedItems([...newlyAddedItems, item])
        }
        //Below line is to unSelect item which has been moved to right
        setSelectedAssignmentsLeft((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }
    const handleDoubleClickForRemoving = (e, item) => {
        if (item?.isInherited && selectedTabRight === 'assigned') {
            return
        }

        item.itemType = itemType ? itemType : null

        if (selectedTabRight === 'assigned') {
            setSearchKey('')

            setSelectedTabLeft('removed')
            setNewlyRemovedItems([...newlyRemovedItems, item])
        } else {
            const arrayAfterDeletingCommon = deleteCommonElements(
                newlyAddedItems,
                [item],
            )
            setSearchKey('')

            setSelectedTabLeft('all')

            setNewlyAddedItems(arrayAfterDeletingCommon)
        }
        //Below line is to unSelect item which has been moved to left
        setSelectedAssignmentsLeft((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }

    const checkIfAllItemsMovedToRight = () => {
        try {
            return unAssignedList.every((item) => assignedList.includes(item))
        } catch (err) {
            return false
        }
    }
    const checkIfRemoveAllButtonDisabled = () => {
        return !(
            !paginatedData_RightSide ||
            paginatedData_RightSide
                ?.filter(
                    (i) => !(i.isInherited && selectedTabRight === 'assigned'),
                )
                .every((j) =>
                    newlyRemovedItems.some(
                        (k) => j[identifier] === k[identifier],
                    ),
                )
        )
    }
    const deleteCommonElements = (array1, array2) => {
        // Filter elements from array1 that are not present in array2 based on the identifier
        const newArray1 = array1.filter(
            (element1) =>
                !array2.some(
                    (element2) => element1[identifier] === element2[identifier],
                ),
        )

        // Now newArray1 contains elements from array1 that are not in array2
        return newArray1
    }
    const onFieldTypeSelection = (fieldTypeObject) => {
        if (fieldTypeObject?.tab === 'added') {
            const updatedItems = newlyAddedItems.map((item) => {
                if (item.id === fieldTypeObject?.itemToAdd?.localId) {
                    return {
                        ...item,
                        fieldTypeObject: fieldTypeObject,
                    }
                }
                return item
            })
            setNewlyAddedItems(updatedItems)
        } else if (fieldTypeObject?.tab === 'assigned') {
            //The case of Edit the field types of existing/assigned items
            const filteredItem = assignedList.find(
                (item) => item.id === fieldTypeObject?.itemToAdd?.assignmentId,
            )
            setModifiedItems([
                ...modifiedItems,
                { ...filteredItem, fieldTypeObject: fieldTypeObject },
            ])
        }
    }

    const preRemove = (item) => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetResourceId: item?.assigneeId,
            assignmentType: 'Remove',
            application: application,
            assigneePerson: item,
            targetAssigneeId:
                targetAssigneeId ||
                item?.targetAssigneeIdAssigned ||
                item?.[fieldTypeProps?.keyForRoleRightId],
            fieldTypeValueJson: undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preAdd = (item) => {
        const transformCartObject = {
            ...fieldTypeProps?.assignee,
            assigneeId: fieldTypeProps?.assignee?.id,
            id:
                targetAssigneeId ||
                item?.targetAssigneeIdAssigned ||
                item?.[fieldTypeProps?.keyForRoleRightId] ||
                item?.id, //target assignee id
            assigneeResourceTypeId: fieldTypeProps?.assignee?.resourceTypeId,
            assigneeResourceTypeFriendlyName:
                fieldTypeProps?.assignee?.resourceTypeName,
            [fieldTypeProps?.keyForRoleRightId]:
                item?.[fieldTypeProps?.keyForRoleRightId],
            assignee: fieldTypeProps?.assignee?.friendlyName,
            itemType: item.itemType,
        }
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetResourceId: transformCartObject?.assigneeId,
            assignmentType: 'Add',
            application: application,
            assigneePerson: transformCartObject,
            targetAssigneeId: targetAssigneeId || transformCartObject?.id,
            fieldTypeValueJson: undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    return (
        <>
            <PageContextProvider key={'PermissionLevelSelection'}>
                {showPermissionLevel && openAssigneeModal && (
                    <>
                        <PermissionLevels
                            value={poilicyAssignee}
                            permissionLevelProps={permissionLevelProps}
                            onChange={(e) => handlePolicyAssigneChange(e)}
                            applicationId={selectedApplicationId}
                        />
                        <Divider />
                    </>
                )}
            </PageContextProvider>
            {showTimeConstrainedFilter && devFeaturesEnabled && (
                <>
                    <Button
                        onClick={
                            () => console.log('Filter permanent list') //TODO : Add when filtering functionality is available
                        }
                        disabled={policy?.isTimeConstrained}
                        className={classNames(
                            classes.timeConstraintSwitchButton,
                            {
                                [classes.activeTimeConstraintSwitchButton]:
                                    !timeConstrained,
                                [classes.disabledTimeConstraintSwitchButton]:
                                    policy?.isTimeConstrained,
                            },
                        )}
                        style={{
                            marginRight: '16px',
                        }}
                        startIcon={
                            <Icon
                                name="Clock"
                                color={timeConstrained ? '#8b909a' : '#265fb4'}
                            />
                        }
                    >
                        {t('Permanent')}
                    </Button>

                    <Button
                        onClick={
                            () => console.log('Filter time constrained list') //TODO : Add when filtering functionality is available
                        }
                        className={classNames(
                            classes.timeConstraintSwitchButton,
                            {
                                [classes.activeTimeConstraintSwitchButton]:
                                    timeConstrained,
                            },
                        )}
                        startIcon={
                            <Icon
                                name="Clock"
                                color={!timeConstrained ? '#8b909a' : '#265fb4'}
                            />
                        }
                    >
                        {t('TimeConstraint')}
                    </Button>

                    <Divider />
                </>
            )}

            {(!showPermissionLevel || poilicyAssignee) && (
                <>
                    <Box className={classes.SearchPeople}>
                        <Box className={classes.searchBar}>
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t(searchPlaceHolder)}
                                value={searchKey}
                                handleClear={handleClear}
                                outlined
                            />
                        </Box>
                    </Box>

                    <Box display="flex" flex={'1'}>
                        <Box
                            className={classes.SelectAssignmentSectionContainer}
                        >
                            <Box className={classes.tabsContainer}>
                                <PillTabs
                                    variant={'standard'}
                                    value={selectedTabLeft}
                                    onChange={(_, tab) => {
                                        setSearchKey('')
                                        setSelectedAssignmentsLeft([])
                                        setPage_LeftSide(1)
                                        setSelectedTabLeft(tab)
                                    }}
                                >
                                    {SECTION_TABS_LEFT.map((tab, i) => {
                                        return (
                                            <Tab
                                                label={
                                                    <TabLabel
                                                        label={tab.label}
                                                        count={
                                                            tabCounts[
                                                                tab.countProp
                                                            ]
                                                        }
                                                        countColor={
                                                            tab.countColor
                                                        }
                                                    />
                                                }
                                                value={tab.value}
                                            />
                                        )
                                    })}
                                </PillTabs>
                            </Box>
                            <Box>
                                <SelectAssignmentSection>
                                    {unAssignedLoading && (
                                        <Box
                                            className={
                                                classes.AssignmentsModalloader
                                            }
                                        >
                                            <Loader />
                                        </Box>
                                    )}
                                    {paginatedData_LeftSide?.length ? (
                                        paginatedData_LeftSide.map(
                                            (item, index) => (
                                                <AssignmentItemWithFieldTypes
                                                    handleSelectPeople={() =>
                                                        handleSelectPeople({
                                                            itemTab:
                                                                selectedTabLeft,
                                                            ...item,
                                                        })
                                                    }
                                                    isSelectedAttribute={checkIfSelected(
                                                        item,
                                                    )}
                                                    isLastSelectedItem={
                                                        lastSelectedItem?.[
                                                            identifier
                                                        ] === item[identifier]
                                                    }
                                                    isUnAssigned={
                                                        item?.isAssigned
                                                    }
                                                    item={{
                                                        ...item,
                                                        startDateUtc:
                                                            getValueFromSelectedArray(
                                                                item,
                                                                'startDateUtc',
                                                            ),
                                                        endDateUtc:
                                                            getValueFromSelectedArray(
                                                                item,
                                                                'endDateUtc',
                                                            ),
                                                    }}
                                                    index={index}
                                                    handleDoubleClick={
                                                        handleDoubleClickForAdding
                                                    }
                                                    showCrossIcon={
                                                        selectedTabLeft ===
                                                        'removed'
                                                    }
                                                    secondaryAttributes={
                                                        showStartDate &&
                                                        getValueFromSelectedArray(
                                                            item,
                                                            'startDateUtc',
                                                        )
                                                            ? [
                                                                  ...secondaryAttributes,
                                                                  'startDateUtc',
                                                                  'endDateUtc',
                                                              ]
                                                            : getValueFromSelectedArray(
                                                                  item,
                                                                  'endDateUtc',
                                                              )
                                                            ? [
                                                                  ...secondaryAttributes,
                                                                  'endDateUtc',
                                                              ]
                                                            : secondaryAttributes
                                                    }
                                                    primaryAttribute={
                                                        primaryAttribute
                                                    }
                                                    tab={selectedTabLeft}
                                                    applicationId={
                                                        applicationId
                                                    }
                                                />
                                            ),
                                        )
                                    ) : !unAssignedLoading &&
                                      checkIfAllItemsMovedToRight() ? (
                                        <Box
                                            className={
                                                classes.AssignmentsAllSelectedContainer
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.AssignmentsModalAllSelected
                                                }
                                            >
                                                <Icon name={'InfoNew'} />
                                                {t(
                                                    !unAssignedList?.length &&
                                                        !searchKey
                                                        ? 'NoDataMessage'
                                                        : !unAssignedList?.length &&
                                                          searchKey
                                                        ? 'NoDataWithSearch'
                                                        : 'AllSelected_Info_Message',
                                                )}
                                            </Box>
                                        </Box>
                                    ) : null}
                                </SelectAssignmentSection>
                            </Box>
                        </Box>

                        <ButtonSection>
                            <div className={classes.ButtonRemoveSection}>
                                {showAccessRequestPolicy &&
                                    policy &&
                                    selectedTabLeft !== 'removed' && (
                                        <>
                                            {fetchRequestPolicyItemWise && (
                                                <>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {
                                                            lastSelectedItem?.friendlyName
                                                        }
                                                    </Typography>

                                                    <Divider
                                                        style={{
                                                            margin: '0.9rem 0',
                                                        }}
                                                    />
                                                </>
                                            )}

                                            <PolicyDetails
                                                policy={policy}
                                                orientation="vertical"
                                                showValuesIfUnrestricted={false}
                                                styles={{
                                                    attributeLabel: {
                                                        minWidth: '190px',
                                                    },
                                                }}
                                            />
                                        </>
                                    )}

                                {!hideDurationSelection &&
                                    selectedTabLeft !== 'removed' &&
                                    selectedAssignmentsLeft.length > 0 && (
                                        <>
                                            {(timeConstrained ||
                                                hasAccessToControl(
                                                    canViewAssignmentPopupDurationControl,
                                                )) && (
                                                <Box
                                                    className={
                                                        classes.toggleSwitch
                                                    }
                                                    data-protectedsubcomponent={
                                                        canViewAssignmentPopupDurationControl
                                                    }
                                                >
                                                    <ToggleSwitch
                                                        value={timeConstrained}
                                                        label={t('SetDuration')}
                                                        labelColor="#5d6870"
                                                        disabled={
                                                            policy?.isTimeConstrained
                                                        }
                                                        onChange={
                                                            handleTimeConstrainedSwitch
                                                        }
                                                    />
                                                </Box>
                                            )}

                                            {timeConstrained && (
                                                <>
                                                    {showStartDate && (
                                                        <Box
                                                            padding={'0px 4px'}
                                                        >
                                                            <StartDateTimePicker
                                                                selectedStartDate={
                                                                    selectedStartDate
                                                                }
                                                                setSelectedStartDate={
                                                                    handleStartDate
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                    <Box padding={'0px 4px'}>
                                                        <EndDateTimePicker
                                                            selectedEndDate={
                                                                selectedEndDate
                                                            }
                                                            setSelectedEndDate={
                                                                handleEndDate
                                                            }
                                                            minEndDate={
                                                                lastSelectedItem?.startDateUtc
                                                                    ? moment(
                                                                          lastSelectedItem?.startDateUtc,
                                                                      )
                                                                    : null
                                                            }
                                                            maxEndDate={
                                                                policy?.isTimeConstrained &&
                                                                policy?.maximumValueInMinutes &&
                                                                lastSelectedItem?.startDateUtc
                                                                    ? moment(
                                                                          lastSelectedItem?.startDateUtc,
                                                                      ).add(
                                                                          policy?.maximumValueInMinutes,
                                                                          'minute',
                                                                      )
                                                                    : null
                                                            }
                                                            hasInvalidDates={
                                                                hasInvalidDates
                                                            }
                                                            validationMessage={
                                                                invalidDatesMessage
                                                            }
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    )}
                                <Button
                                    onClick={() => handleAddSelectedPeople()}
                                    disabled={
                                        !selectedAssignmentsLeft?.length ||
                                        hasInvalidDates
                                    }
                                    className={classNames(
                                        classes.inactiveButtonForAdd,
                                        {
                                            [classes.activeButtonForAdd]:
                                                selectedAssignmentsLeft?.length &&
                                                !hasInvalidDates,
                                        },
                                    )}
                                    style={{
                                        marginTop: showAccessRequestPolicy
                                            ? '15px !important'
                                            : '60px',
                                    }}
                                >
                                    <label>{t('Add')}</label>

                                    <ArrowSmallIcon
                                        color="#8b909a"
                                        fill="#8b909a"
                                        direction="right"
                                    />
                                </Button>

                                <Button
                                    onClick={() => handleRemove('selected')}
                                    disabled={!selectedAssignmentsRight?.length}
                                    className={classNames(
                                        classes.inactiveButtonForRemove,
                                        {
                                            [classes.activeButtonForRemove]:
                                                selectedAssignmentsRight?.length,
                                        },
                                    )}
                                >
                                    <ArrowSmallIcon
                                        color="#8b909a"
                                        fill="#8b909a"
                                        direction="left"
                                    />
                                    <label>{t('Remove')}</label>
                                </Button>
                                <Button
                                    onClick={() => handleRemove('all')}
                                    disabled={!checkIfRemoveAllButtonDisabled()}
                                    className={classNames(
                                        classes.inactiveButtonForRemoveAll,
                                        {
                                            [classes.activeButtonForRemoveAll]:
                                                checkIfRemoveAllButtonDisabled(),
                                        },
                                    )}
                                >
                                    <Icon
                                        style={{
                                            fill: checkIfRemoveAllButtonDisabled()
                                                ? '#ff4d5f'
                                                : '#8b909a',
                                        }}
                                        direction={'left'}
                                        name={'DoubleArrowSmall'}
                                    />
                                    <label>{t('RemoveAll')}</label>
                                </Button>
                            </div>
                        </ButtonSection>

                        <Box
                            className={classes.SelectAssignmentSectionContainer}
                        >
                            <Box className={classes.tabsContainer}>
                                <PillTabs
                                    value={selectedTabRight}
                                    onChange={(_, tab) => {
                                        setSelectedTabRight(tab)
                                        setSelectedAssignmentsRight([])
                                        setPage_RightSide(1)
                                    }}
                                >
                                    {SECTION_TABS_RIGHT.map((tab, i) => {
                                        return (
                                            <Tab
                                                key={tab.value + i}
                                                label={
                                                    <TabLabel
                                                        label={tab.label}
                                                        count={
                                                            tabCounts[
                                                                tab.countProp
                                                            ]
                                                        }
                                                        countColor={
                                                            tab.countColor
                                                        }
                                                    />
                                                }
                                                value={tab.value}
                                            />
                                        )
                                    })}
                                </PillTabs>
                            </Box>
                            <Box>
                                <SelectAssignmentSection>
                                    {assignedLoading && (
                                        <Box
                                            className={
                                                classes.AssignmentsModalloader
                                            }
                                        >
                                            <Loader />
                                        </Box>
                                    )}
                                    {paginatedData_RightSide?.length
                                        ? paginatedData_RightSide.map(
                                              (listItem, index) => {
                                                  let item = {
                                                      ...listItem,
                                                      [fieldTypeProps?.keyForRoleRightId]:
                                                          listItem?.[
                                                              fieldTypeProps
                                                                  ?.keyForRoleRightId
                                                          ]
                                                              ? listItem[
                                                                    fieldTypeProps
                                                                        ?.keyForRoleRightId
                                                                ]
                                                              : listItem?.id,
                                                  }
                                                  return (
                                                      <AssignmentItemWithFieldTypes
                                                          handleSelectPeople={
                                                              handleAddedPeopleChange
                                                          }
                                                          isSelectedAttribute={checkIfRemoveSelected(
                                                              item,
                                                          )}
                                                          item={item}
                                                          index={index}
                                                          handleDoubleClick={
                                                              handleDoubleClickForRemoving
                                                          }
                                                          showPlusIcon={
                                                              selectedTabRight ===
                                                              'added'
                                                          }
                                                          secondaryAttributes={
                                                              item?.timeConstrained
                                                                  ? showStartDate
                                                                      ? [
                                                                            ...secondaryAttributes,
                                                                            'startDateUtc',
                                                                            'endDateUtc',
                                                                        ]
                                                                      : [
                                                                            ...secondaryAttributes,
                                                                            'endDateUtc',
                                                                        ]
                                                                  : secondaryAttributes
                                                          }
                                                          isInherited={
                                                              item?.isInherited &&
                                                              selectedTabRight ===
                                                                  'assigned'
                                                          }
                                                          primaryAttribute={
                                                              primaryAttribute
                                                          }
                                                          tab={selectedTabRight}
                                                          fieldTypeProps={{
                                                              //   tab: selectedTabRight,
                                                              ...fieldTypeProps,
                                                          }}
                                                          onFieldTypeSelection={(
                                                              f,
                                                          ) => {
                                                              onFieldTypeSelection(
                                                                  f,
                                                              )
                                                          }}
                                                          applicationId={
                                                              applicationId
                                                          }
                                                      />
                                                  )
                                              },
                                          )
                                        : null}
                                </SelectAssignmentSection>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex">
                        <PaginationMain>
                            {numberOfPages_LeftSide >= 1 && (
                                <Pagination
                                    count={numberOfPages_LeftSide}
                                    size="small"
                                    page={page_LeftSide}
                                    onChange={handlePageChange_LeftSide}
                                    variant={'rounded'}
                                    siblingCount={0}
                                />
                            )}
                        </PaginationMain>
                        <Box
                            style={{
                                maxWidth: '25rem',
                                width: '100%',
                            }}
                        ></Box>
                        <PaginationMain>
                            {numberOfPages_RightSide >= 1 && (
                                <Pagination
                                    count={numberOfPages_RightSide}
                                    size="small"
                                    page={page_RightSide}
                                    onChange={handlePageChange_RightSide}
                                    variant={'rounded'}
                                    siblingCount={0}
                                />
                            )}
                        </PaginationMain>
                    </Box>
                </>
            )}

            <ModalFooter className={classes.TimeSectionFooter}>
                <Tooltip title={getTooltipTitle()}>
                    <div>
                        <Button
                            loading={isSubmitting}
                            onClick={() => handleSave()}
                            backgroundColor={theme?.palette?.primary?.main}
                            disabled={hasTooltipValue}
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                borderColor: '#aab0b4 !important',
                                color: '#ffffff !important',
                                display: 'inline-block',
                            }}
                        >
                            {t('Common_AddToCart')}
                        </Button>
                    </div>
                </Tooltip>
                <Button onClick={() => handleModalClose()}>
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </>
    )
}

export default EditAssignmentsWithFieldTypes
