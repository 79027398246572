import { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import { Collapse, Box, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
} from 'packages/core'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import ApplicationDetails from './ApplicationDetails'
import {
    ApplicationsTable,
    ApplicationsTableHead,
    ApplicationRow,
} from './ApplicationsTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'

import { useApplicationSettings } from 'hooks'
import { ApplicationBanner } from 'components/ApplicationBanner'
import { PageContextProvider, usePageContext } from 'pageContext'
import { useOwnedApplications } from 'hooks'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import ListingTabs from 'components/ListingTabs'

import ClaimsMappingPolicyDetails from './ClaimsMappingPolicy/PolicyDetails'
import ResourceMoreInformationTile from 'components/ResourceMoreInformationTile'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'
import { ITEMS_VIEW, RESOURCE_TYPE_NAMES, isApplicationAzureApp } from 'utils'
import GenericButton from 'components/Button/GenericButton'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import CardView from 'components/EidTableGrid/CardView'
import OnboardApplication from 'components/OnBoardApplication'
import GlobalFieldTypeListing from './GlobalFieldTypes'
import FieldTypeDrawer from 'components/ApplicationBanner/PbacDefinitions/AppOwnedFieldTypes/AppOwnedFieldTypeDetails/FieldTypeDrawer'

const canSeeMoreInformation = 'ResourceAdmin-Applications-MoreInfo-Control'
const actionButtonsControlName = 'ResourceAdmin-Application-List-Actions'
const canSeeOnboardApplication =
    'ResourceAdmin-Applications-CreateAzureApplicationWorkflow-Control'

const ApplicationsLayout = ({ _filters }) => {
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()

    const LISTING_TABS = {
        name: 'ListingTabs',
        requireAccess: 'ResourceAdmin-Applications-Navigation-Tabs-Control',
        tabs: [
            {
                label: 'ApplicationsList',
                name: 'ApplicationsList',
                display: true,
                showCount: true,
                icon: 'Applications',
                default: true,
                path: 'applications',
            },
            {
                label: 'ClaimsMappingPolicies',
                name: 'ClaimsPolicies',
                display: true,
                showCount: true,
                icon: 'Claims',
                path: 'claimsMappingPolicies',
                url: 'api/claimsmappingpolicy/all',
                requireAccess:
                    'ResourceAdmin-Applications-Navigation-Tabs-ClaimsMappingPolicies',
            },
            {
                label: 'GlobalFieldTypes',
                name: 'GlobalFieldTypes',
                display: true,
                showCount: true,
                icon: 'Scope',
                path: 'globalFieldTypes',
                url: 'api/Applications/globalFieldTypes',
                requireAccess:
                    'ResourceAdmin-Applications-Navigation-Tabs-GlobalFieldTypes',
            },
        ],
    }
    const hasAccessToViewListingTabs = hasAccessToControl(
        LISTING_TABS.requireAccess,
    )

    LISTING_TABS.tabs = LISTING_TABS.tabs.filter((x) =>
        x.requireAccess ? hasAccessToControl(x.requireAccess) : true,
    )

    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const query = useQuery()
    const selectedTab = query.get('tab')
    const [state, dispatch] = usePageContext()
    const [listingTab, setListingTab] = useState(
        hasAccessToViewListingTabs && selectedTab
            ? selectedTab
            : LISTING_TABS.tabs.find((x) => x.default)?.path,
    )
    const selectedListingTab = LISTING_TABS.tabs.filter(
        (f) => f.path.toLowerCase() === listingTab.toLowerCase(),
    )[0]
    const filters = _filters
        .filter((f) => f.tabs[selectedListingTab.path])
        .map((f) => f)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
        rest,
    } = useOwnedApplications(selectedListingTab)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [filterWidth, setFilterWidth] = useState(0)

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])

    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? ITEMS_VIEW.CARD : ITEMS_VIEW.LIST

    const applicationAttributes = attributes.filter(
        (a) => !a.isClaimPolicyAttributeOnly,
    )
    const claimMappingPolicyAttributes = attributes.filter(
        (a) => a.isClaimPolicyAttributeOnly || a.isClaimPolicyAttribute,
    )
    const listingAttributes =
        listingTab === 'applications'
            ? applicationAttributes
            : claimMappingPolicyAttributes

    const isClaimsPolicyCard =
        getAccessBasedAttributes(listingAttributes).findIndex(
            (x) => x.isClaimPolicyAttributeOnly,
        ) > -1

    const getActionVerb = (item) => {
        if (isApplicationAzureApp(item?.applicationType) || isClaimsPolicyCard)
            return 'AzureApps'
        return 'Apps'
    }

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoDataMessage'),
        pagination,
        attributes: getAccessBasedAttributes(listingAttributes),
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
            renderLeftButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box
                        data-protectedsubcomponent={actionButtonsControlName}
                        width="80%"
                    >
                        <GenericButton
                            onClick={() => {
                                history.push(
                                    (isClaimsPolicyCard
                                        ? `?claimsMappingPolicyId=`
                                        : `?applicationId=`) + item?.id,
                                )
                            }}
                            color="#01ae8f"
                            rootStylesProp={{
                                borderRadius: '4px',
                                width: '100%',
                                height: '32px',
                            }}
                        >
                            {t('Details')}
                        </GenericButton>
                    </Box>
                ) : (
                    <></>
                )
            },
            renderRightButton: (item) => {
                return hasAccessToControl(actionButtonsControlName) ? (
                    <Box data-protectedsubcomponent={actionButtonsControlName}>
                        <UIActionsGearButton
                            item={item}
                            noun={
                                isClaimsPolicyCard
                                    ? 'ResourceAdminClaims'
                                    : 'ResourceAdmin'
                            }
                            verb={getActionVerb(item)}
                        />
                    </Box>
                ) : (
                    <></>
                )
            },
        },
        tableViewConfig: {
            TableComponent: ApplicationsTable,
            TableHeadComponent: ApplicationsTableHead,
            TableRowComponent: ApplicationRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView(ITEMS_VIEW.CARD)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallScreen])

    const applicationId = query.get('applicationId')
    const claimsMappingPolicyId = query.get('claimsMappingPolicyId')
    const fieldTypeId = query.get('fieldTypeId')
    const showDetailsPage = applicationId || claimsMappingPolicyId

    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig

    const accessibleStaticWorkflows =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) =>
                      w.resourceTypeName === RESOURCE_TYPE_NAMES.APPLICATIONS &&
                      hasAccessToControl(w.protectedSubcomponentName),
              )
            : []

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let detailContent
    if (applicationId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <ApplicationDetails
                        id={applicationId}
                        onClose={() => {
                            history.push(history.location.pathname)
                            refetch()
                        }}
                    />
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <ApplicationBanner applicationId={applicationId} />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    if (claimsMappingPolicyId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <ClaimsMappingPolicyDetails id={claimsMappingPolicyId} />
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <ClaimsMappingPolicyDetails
                                id={claimsMappingPolicyId}
                            />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    let listingContent
    listingContent = (
        <>
            {isSmallScreen ? (
                <SmallScreenContainer>
                    <Box display={showFilters ? '' : 'none'}>
                        {mobileFiltersScreen}
                    </Box>

                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={''}>
                            <StickyContainer>
                                <Box width="100%">
                                    {mobileStickyContentListing}
                                </Box>
                            </StickyContainer>
                            {viewToRender}
                        </Box>
                    </Box>
                </SmallScreenContainer>
            ) : (
                <DesktopContainer>
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToViewListingTabs ||
                            accessibleStaticWorkflows.length > 0 ||
                            hasAccessToControl(canSeeMoreInformation)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={
                                                    openFilters
                                                        ? t('Hide_My_Filters')
                                                        : t('Show_My_Filters')
                                                }
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {hasAccessToViewListingTabs && (
                                        <Box
                                            style={{
                                                marginBottom: '28px',
                                                width: '100%',
                                            }}
                                            data-protectedsubcomponent={
                                                LISTING_TABS.requireAccess
                                            }
                                        >
                                            <Paper>
                                                <ListingTabs
                                                    active={listingTab}
                                                    tabs={LISTING_TABS.tabs}
                                                    activeTab={
                                                        LISTING_TABS.tabs.filter(
                                                            (t) =>
                                                                t.path ===
                                                                listingTab,
                                                        )[0]
                                                    }
                                                    onChange={(tab) => {
                                                        dispatch({
                                                            type: 'CLEAR_FILTERS_SORTING',
                                                            payload: null,
                                                        })
                                                        setListingTab(tab.path)
                                                        history.push(
                                                            !tab.default
                                                                ? `${history.location.pathname}?tab=${tab.path}`
                                                                : `${history.location.pathname}`,
                                                        )
                                                    }}
                                                    count={totalCount}
                                                />
                                            </Paper>
                                        </Box>
                                    )}
                                    <Box
                                        style={{
                                            marginBottom: '2.8rem',
                                        }}
                                    >
                                        <OnboardApplication
                                            accessibleStaticWorkflows={
                                                accessibleStaticWorkflows
                                            }
                                            protectedSubcomponent={
                                                canSeeOnboardApplication
                                            }
                                        />
                                    </Box>
                                    <ResourceMoreInformationTile
                                        moreInfoKey={
                                            listingTab === 'applications'
                                                ? 'MoreInfo_Html'
                                                : 'ClaimsMappingPolicyMoreInfo_Html'
                                        }
                                        protectedsubcomponent={
                                            canSeeMoreInformation
                                        }
                                        resourceId={applicationId}
                                    />
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender
                                                        config={f.config}
                                                    />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="Application"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            {listingTab === 'globalFieldTypes' ? (
                                <GlobalFieldTypeListing
                                    list={list}
                                    loading={isLoading}
                                    loadingMore={isLoadingMore}
                                    pagination={pagination}
                                    refetch={refetch}
                                />
                            ) : (
                                <>
                                    <Box display="flex">
                                        <Box width="100%">
                                            <Filters.TextSearch />
                                        </Box>
                                    </Box>
                                    <GridContainer
                                        filterWidth={filterWidth}
                                        openFilters={openFilters}
                                    >
                                        {viewToRender}
                                    </GridContainer>
                                </>
                            )}
                        </ListingGrid>
                    </Fragment>
                </DesktopContainer>
            )}
        </>
    )

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={!showDetailsPage}
            showBackButton={showDetailsPage}
        >
            <Fragment>
                {showDetailsPage ? detailContent : listingContent}
                <>
                    {fieldTypeId && (
                        <PageContextProvider key="RequestViewFieldTypes">
                            <FieldTypeDrawer
                                id={fieldTypeId}
                                applicationId={applicationId}
                                isGlobalFieldType={true}
                                onClose={() => {
                                    query.delete('fieldTypeId')
                                    query.delete('drawerActiveTab')
                                    history.push(`${history.location.pathname}`)
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                            />
                        </PageContextProvider>
                    )}
                </>
            </Fragment>
        </AppLayout>
    )
}

export default ApplicationsLayout
