import './index.css'
import {
    PhoneInput as IntlTelInput,
    PhoneInputProps,
} from 'react-international-phone'
const PhoneNumberInputField: React.FC<PhoneInputProps> = ({
    value,
    hideDropdown,
    defaultCountry,
    showDisabledDialCodeAndPrefix,
    disableFocusAfterCountrySelect,
    disableDialCodeAndPrefix,
    flags,
    prefix,
    forceDialCode,
    inputProps,
    onFocus,
    onChange,
    onBlur,
    name,
    required,
    autoFocus,
    disabled,
    placeholder,
    countries,
    preferredCountries,
    dialCodePreviewStyleProps,
    countrySelectorStyleProps,
    disableDialCodePrefill,
    defaultMask,
    charAfterDialCode,
    disableFormatting,
    disableCountryGuess,
    historySaveDebounceMS,
    inputRef,
    style,
}) => {
    return (
        <IntlTelInput
            value={value}
            onChange={onChange}
            defaultCountry={defaultCountry}
            className="main"
            disableDialCodePrefill={disableDialCodePrefill}
            placeholder={placeholder}
            forceDialCode={forceDialCode}
            hideDropdown={hideDropdown}
            disableFocusAfterCountrySelect={disableFocusAfterCountrySelect}
            flags={flags}
            inputProps={inputProps}
            onBlur={onBlur}
            name={name}
            disableFormatting={disableFormatting}
            prefix={prefix}
            defaultMask={defaultMask}
            charAfterDialCode={charAfterDialCode}
            historySaveDebounceMS={historySaveDebounceMS}
            disableCountryGuess={disableCountryGuess}
            required={required}
            autoFocus={autoFocus}
            onFocus={onFocus}
            inputRef={inputRef}
            disabled={disabled}
            countries={countries}
            style={style}
            preferredCountries={preferredCountries}
            dialCodePreviewStyleProps={dialCodePreviewStyleProps}
            countrySelectorStyleProps={countrySelectorStyleProps}
            showDisabledDialCodeAndPrefix={showDisabledDialCodeAndPrefix}
            disableDialCodeAndPrefix={disableDialCodeAndPrefix}
        />
    )
}
export default PhoneNumberInputField
