import { Box, styled, useTheme } from '@mui/material'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplication, useApplicationRoleDefinitionsAssignments } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Button, Filters } from 'components'
import { Icon } from 'packages/eid-icons'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import UnAssignAssignment from '../Assignments/UnAssignAssignment'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'
import FieldTypeJsonPreviewButton from 'components/FieldTypeJsonPreview'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import useSubcomponents from 'useSubcomponents'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import { useState } from 'react'
import { AssignRolesToPerson } from 'components/AssignRolesToResources/AssignRolesToPerson'
import { AssignRolesToManagementRole } from 'components/AssignRolesToResources/AssignRolesToManagementRole'
import { AssignRolesToBusinessRolesLocation } from 'components/AssignRolesToResources/AssignRolesToBusinessRoleLocation'
import { AssignRolesToSetGroup } from 'components/AssignRolesToResources/AssignRolesToSetGroups'
import { AssignRolesToGroup } from 'components/AssignRolesToResources/AssignRolesToGroups'
const hasAccessToAssignRolesToResources =
    'ResourceAdmin-AddApplicationAppRolesAssignments-Control'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
})

const RoleDefinitionAssignments = ({ roleId, role }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationRoleDefinitionsAssignments(roleId)
    const applicationId = query.get('applicationId')
    const { data: application } = useApplication(applicationId)
    const [assignmentModalValue, setAssignmentModalValue] = useState('')
    const assignRolesDropdownOptions = [
        {
            label: 'AssignToPerson',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToPerson',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToPerson')
            },
        },
        {
            label: 'AssignToManagementRole',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToManagementRole',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToManagementRole')
            },
        },
        {
            label: 'AssignToGroup',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToGroup')
            },
        },
        {
            label: 'AssignToBusinessRoleLocation',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToBusinessRoleLocation',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToBusinessRoleLocation')
            },
        },
        {
            label: 'AssignToSetGroup',
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Applications-AppRolesAssignment-AssignToSetGroup',
            },
            onClick: () => {
                setAssignmentModalValue('AssignToSetGroup')
            },
        },
    ]
    const getOptionsToRender = () => {
        return assignRolesDropdownOptions.filter(
            (d) => d.display && hasAccessToControl(d?.requireAccess?.control),
        )
    }
    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            label: 'Assignee',
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <AssigneePerson
                            assigneeName={data.assignee}
                            imageUrl={data.personImageThumbUrl}
                            assigneeType={data.type}
                        />
                    )
                },
            },
        },
        {
            name: 'role',
            sortable: true,
            label: 'Role',
        },
        {
            name: 'assignedTo',
            sortable: true,
            label: 'AssignedTo',
        },

        {
            name: 'tenant',
            sortable: true,
            label: 'Tenant',
        },
        {
            name: 'startDate',
            sortable: true,
            label: 'StartDate',
            styles: {
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <DateTimeGenericComponent
                        data={data}
                        attribute={{ name: 'startDateUtc' }}
                        format={'lll'}
                    />
                ),
            },
        },
        {
            name: 'endDate',
            sortable: true,
            label: 'EndDate',
            styles: {
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <DateTimeGenericComponent
                        data={data}
                        attribute={{ name: 'endDateUtc' }}
                        format={'lll'}
                    />
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            <FieldTypeJsonPreviewButton data={data} />
                            <UnAssignAssignment
                                assignmentId={data?.id}
                                refetch={refetch}
                                friendlyName={data?.assignee}
                            />
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 30px"
            >
                {hasAccessToControl(hasAccessToAssignRolesToResources) && (
                    <Box
                        data-protectedsubcomponent={
                            hasAccessToAssignRolesToResources
                        }
                    >
                        {getOptionsToRender().length > 0 && (
                            <ButtonWithDropdown
                                label={'AssignAppRoles'}
                                options={getOptionsToRender()}
                            />
                        )}
                    </Box>
                )}
                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    sLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes}
                    list={list}
                />
            </TableOverView>
            {assignmentModalValue === 'AssignToPerson' && (
                <AssignRolesToPerson
                    dynamictext={application?.friendlyName}
                    resourceId={roleId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/AzureRoles/assigmentsByRoleDefinition/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToManagementRole' && (
                <AssignRolesToManagementRole
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/AzureRoles/assigmentsByRoleDefinition/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToGroup' && (
                <AssignRolesToGroup
                    dynamictext={application?.friendlyName}
                    resourceId={roleId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/AzureRoles/assigmentsByRoleDefinition/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}

            {assignmentModalValue === 'AssignToSetGroup' && (
                <AssignRolesToSetGroup
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/AzureRoles/assigmentsByRoleDefinition/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
            {assignmentModalValue === 'AssignToBusinessRoleLocation' && (
                <AssignRolesToBusinessRolesLocation
                    dynamictext={application?.friendlyName}
                    resourceId={applicationId}
                    targetAssigneeId={roleId}
                    showAssignmentModal={true}
                    setAssignmentModalValue={setAssignmentModalValue}
                    assignedUrl={`api/AzureRoles/assigmentsByRoleDefinition/${roleId}`}
                    unAssignedPredefinedList={[role]}
                />
            )}
        </Box>
    )
}

export default RoleDefinitionAssignments
