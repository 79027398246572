import React, { Fragment, useEffect, useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { SearchMenuBarFilter } from 'components/WrapperComponents/SearchMenuBar'
import { Box } from '@mui/material'
import useSubcomponents from 'useSubcomponents'
import { useAddItemsToCart, useCart } from 'hooks'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { Button, Filters, GridContainer } from 'components'
import { Tooltip, WarningModal } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import cartHelpers from 'containers/Cart/cartHelpers'
import { RootButtonContainer } from 'components/ApplicationBanner/PbacDefinitions/AppOwnedFieldTypes'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { RESOURCE_TYPE_NAMES } from 'utils'
import { useAppState } from 'appContext'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import config from 'config'

const canAddNewGlobalFieldTypeControlName =
    'ResourceAdmin-Applications-AddNewGlobalFieldType-Control'
const canSeeAppOwnedFieldTypeDetail =
    'ResourceAdmin-Applications-GlobalFieldTypeDetails-Control'
const canDeleteAppOwnedFieldType =
    'ResourceAdmin-Applications-DeleteGlobalFieldType-Control'
const canSeeUIActionsButtonControlName =
    'ResourceAdmin-Applications-GlobalFieldType-UIActions-Control'

const GlobalFieldTypeListing = ({
    list,
    loading,
    loadingMore,
    refetch,
    pagination,
}) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()
    const [addItemsToCart, { isLoading: addingToCart }] = useAddItemsToCart()
    const query = useQuery()
    const history = useHistory()
    const { data: cart } = useCart()
    const [itemToDelete, setItemToDelete] = useState(null)
    const [, dispatch] = useAppState()
    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'FriendlyName',
        },
        {
            name: 'name',
            sortable: true,
            label: 'TechnicalName',
            requireAccess: {
                control: 'ResourceAdmin-Application-List-GlobalFieldType-Name',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-List-GlobalFieldType-Description',
            },
        },
        {
            name: 'lookupSupported ',
            hideValue: true,
            sortable: true,
            label: 'GridLookupSupported',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-List-GlobalFieldType-GridLookUpSupported',
            },
            component: {
                componentName: 'renderer',
                render: (item) => (
                    <BooleanValue
                        data={{ item }}
                        attribute={{ name: 'lookupSupported' }}
                    />
                ),
            },
        },
        {
            name: 'selectionRuleName',
            sortable: true,
            label: 'DefaultSelectionRule',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-List-GlobalFieldType-DefaultSelectionRule',
            },
        },

        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-List-GlobalFieldType-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                canSeeAppOwnedFieldTypeDetail,
                            ) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        data-protectedsubcomponent={
                                            canSeeAppOwnedFieldTypeDetail
                                        }
                                    >
                                        <Button.Details
                                            item={data}
                                            onClick={() => {
                                                history.push(
                                                    `${history.location.search}&fieldTypeId=${data?.id}`,
                                                )
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            {hasAccessToControl(canDeleteAppOwnedFieldType) && (
                                <Tooltip
                                    title={
                                        alreadyInCart(data)
                                            ? t('Common_ItemAlreadyInCart')
                                            : ''
                                    }
                                >
                                    <Box
                                        marginLeft={'1.6rem'}
                                        data-protectedsubcomponent={
                                            canDeleteAppOwnedFieldType
                                        }
                                    >
                                        <Button.Delete
                                            showEndIcon={false}
                                            disabled={
                                                alreadyInCart(data) ||
                                                !data.isDeletable
                                            }
                                            onClick={() => {
                                                setItemToDelete(data)
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                            {hasAccessToControl(
                                canSeeUIActionsButtonControlName,
                            ) && (
                                <Box
                                    marginLeft={'1.6rem'}
                                    data-protectedsubcomponent={
                                        canSeeUIActionsButtonControlName
                                    }
                                >
                                    <UIActionsGearButton
                                        item={data}
                                        noun={'ResourceAdmin'}
                                        verb={'GlobalFieldTypes'}
                                    />
                                </Box>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    const alreadyInCart = (item) => {
        const isAlreadyInCart = cartHelpers.isItemAlreadyInCart(cart, item?.id)
        return isAlreadyInCart
    }

    useEffect(() => {
        if (itemToDelete?.id) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: itemToDelete?.id,
                    targetAssigneeFriendlyName: itemToDelete?.friendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.GLOBAL_FIELD_TYPE,
                    ),
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: itemToDelete?.id,
                    targetAssigneeFriendlyName: itemToDelete?.friendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.GLOBAL_FIELD_TYPE,
                    ),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToDelete])

    const handleDelete = () => {
        let itemToRemove = cartHelpers.removeFieldType({
            targetAssigneeId: itemToDelete?.id,
            resource: itemToDelete,
            resourceTypeId: itemToDelete?.resourceTypeId,
            resourceTypeName: itemToDelete?.resourceTypeFriendlyName,
            requestableResourceId: itemToDelete?.id,
            resourceFriendlyName: itemToDelete?.friendlyName,
        })
        itemToRemove = {
            ...itemToRemove,
            resourceTypeRoleId: undefined,
        }
        addItemsToCart([itemToRemove]).then(() => {
            setItemToDelete(null)
            refetch()
        })
    }
    return (
        <Fragment>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
            >
                {hasAccessToControl(canAddNewGlobalFieldTypeControlName) && (
                    <RootButtonContainer
                        data-protectedsubcomponent={
                            canAddNewGlobalFieldTypeControlName
                        }
                        onClick={() => {
                            query.set('workflowName', 'OnboardAzFieldType')
                            query.set('returnUrl', getFullPathFromUrl())
                            query.delete('tab')
                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/applications/workflows?${query.toString()}`,
                            )
                        }}
                    >
                        {<Icon name="Plus" color="#fff" />}
                        {t('AddNewGlobalFieldType')}
                    </RootButtonContainer>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Filters.TextSearch outlined />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <GridContainer>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
            {itemToDelete && (
                <WarningModal
                    title={t('Delete') + ' ' + itemToDelete?.friendlyName}
                    description={t('GenericDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    cancel={() => setItemToDelete(null)}
                    proceed={handleDelete}
                    proceeding={addingToCart}
                    open={itemToDelete}
                />
            )}
        </Fragment>
    )
}

export default GlobalFieldTypeListing
