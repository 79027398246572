import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EidButton, Tooltip, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
import { useAddItemsToCart, useCart } from 'hooks'
import cartHelpers from 'containers/Cart/cartHelpers'
import { useAppState } from 'appContext'
import { RESOURCE_TYPE_NAMES } from 'utils'

const DeleteFieldType = ({ item, applicationId, drawerView, ...rest }) => {
    const { t } = useTranslation()
    const { data: cart } = useCart()

    const [addItemsToCart, { isLoading: addingToCart }] = useAddItemsToCart()

    const [showConfirm, setShowConfirm] = useState(false)

    const alreadyInCart = (item) => {
        const isAlreadyInCart = cartHelpers.isItemAlreadyInCart(cart, item?.id)
        return isAlreadyInCart
    }
    const [, dispatch] = useAppState()

    useEffect(() => {
        if (item?.id && !applicationId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: item?.id,
                    targetAssigneeFriendlyName: item?.friendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.GLOBAL_FIELD_TYPE,
                    ),
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: item?.id,
                    targetAssigneeFriendlyName: item?.friendlyName,
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.GLOBAL_FIELD_TYPE,
                    ),
                },
            })
        }
    }, [item])
    const handleDelete = () => {
        const itemToRemove = cartHelpers.removeFieldType({
            targetAssigneeId: applicationId ? applicationId : item?.id,
            resource: item,
            resourceTypeId: item?.resourceTypeId,
            resourceTypeName: item?.resourceTypeFriendlyName,
            requestableResourceId: item?.id,
            resourceFriendlyName: item?.friendlyName,
        })
        addItemsToCart([itemToRemove]).then(() => {
            setShowConfirm(false)
        })
    }
    return (
        <>
            {!drawerView ? (
                <Button.Delete
                    item={item}
                    onClick={() => setShowConfirm(true)}
                    disabled={alreadyInCart(item) || !item.isDeletable}
                    {...rest}
                />
            ) : (
                <Box width="150px" marginLeft={'40px'}>
                    <Tooltip
                        title={
                            alreadyInCart(item)
                                ? t('Common_ItemAlreadyInCart')
                                : ''
                        }
                    >
                        <EidButton.DrawerActionButton
                            disabled={alreadyInCart(item) || !item.isDeletable}
                            fontColor="#ffffff"
                            bgColor="#E40000"
                            width="100%"
                            onClick={() => setShowConfirm(true)}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                height={'12px'}
                                width={'12px'}
                            >
                                <Icon
                                    name="DeleteX"
                                    fill="#ffffff"
                                    color="#ffffff"
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                marginLeft="10px"
                            >
                                <Typography style={{ color: '#ffffff' }}>
                                    {t('Delete')}
                                </Typography>
                            </Box>
                        </EidButton.DrawerActionButton>
                    </Tooltip>
                </Box>
            )}
            <WarningModal
                title={t('Delete') + ' ' + item?.friendlyName}
                description={t('GenericDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setShowConfirm(false)}
                proceeding={addingToCart}
                proceed={handleDelete}
            />
        </>
    )
}

DeleteFieldType.defaultProps = {
    drawerView: false,
}
export default DeleteFieldType
